import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import configuration from "../../configuration/products.json"

const CheckIcon = () => (
  <svg
    className="flex-shrink-0 w-6 h-6 text-green-500"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 13l4 4L19 7"
    />
  </svg>
)

export const Product: React.FC<{
  id: string
}> = ({ id }) => {
  const data = useStaticQuery(graphql`
    query GetAllProducts {
      allProductsJson {
        nodes {
          file: parent {
            ... on File {
              name
            }
          }
          illustration
          description
          advantages
          name
          amazon
          price
          url
        }
      }
    }
  `)

  const favorite = data.allProductsJson.nodes.find(
    product => product.file.name === id
  )

  console.log(favorite)

  return (
    <div className="py-8">
      <div className="relative z-0">
        <div className="absolute inset-0 h-5/6 lg:h-2/3"></div>
        <div className="max-w-4xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="relative">
            <div className="w-full mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
              <div className="relative z-10 bg-white border-2 border-green-500 rounded-lg shadow-xl">
                <div className="md:grid md:grid-cols-2">
                  <div className="px-6 pt-12 pb-10 rounded-lg">
                    <div>
                      <OutboundLink
                        href={favorite.url}
                        target="_blank"
                        rel="sponsored"
                        className="block text-xl font-semibold text-center text-gray-900 sm:-mx-6"
                        id="tier-growth"
                      >
                        {favorite.name}
                      </OutboundLink>
                      <div className="flex items-center justify-center mt-8">
                        <div
                          style={{
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundImage: `url(${favorite.illustration})`,
                          }}
                          className="w-48 h-48"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between flex-1 p-6 rounded-r-lg bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                    <ul className="space-y-4">
                      {favorite.advantages.map(value => (
                        <li className="flex items-start" key={value}>
                          <div className="flex-shrink-0">
                            <CheckIcon />
                          </div>
                          <p className="ml-3 text-base font-medium text-gray-500">
                            {value}
                          </p>
                        </li>
                      ))}
                    </ul>
                    {favorite.amazon && (
                      <div className="mt-10">
                        <div className="rounded-lg shadow-md">
                          <OutboundLink
                            href={favorite.amazon}
                            target="_blank"
                            rel="sponsored"
                            className="block w-full px-6 py-4 text-xl font-medium leading-6 text-center text-gray-900 bg-white border border-transparent rounded-lg hover:bg-gray-100"
                            aria-describedby="tier-growth"
                          >
                            {configuration.amazon}
                          </OutboundLink>
                        </div>
                      </div>
                    )}
                    {favorite.url && (
                      <div className="mt-10">
                        <div className="rounded-lg shadow-md">
                          <OutboundLink
                            href={favorite.url}
                            target="_blank"
                            rel="sponsored"
                            className="block w-full px-6 py-4 text-xl font-medium leading-6 text-center text-white bg-green-500 border border-transparent rounded-lg hover:bg-green-600"
                            aria-describedby="tier-growth"
                          >
                            {configuration.url}
                          </OutboundLink>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
