import React from "react"
import { PageProps } from "gatsby"
import { Footer } from "../components/Footer"
import Layout from "../components/Layout"
import configuration from "../configuration/code-promo.json"
import Seo from "../components/Seo"
import { Navigation } from "../components/Navigation"
import { Product } from "../components/article/Product"

const Privacy: React.FC<PageProps> = props => {
  return (
    <Layout>
      <Seo
        title={configuration.seo.title}
        description={configuration.seo.description}
      />

      <Navigation />

      <div className="container max-w-6xl px-4 pb-10 mx-auto mt-10">
        <div className="max-w-2xl mx-auto">
          <h1 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
          CHANOYU : -10% de réduction valable sur tout le site Kumiko Matcha
          </h1>
          <div className="mt-10 prose">
            <p>
              Vous souhaitez acheter du thé matcha biologique et de grande qualité ?
              Vous êtes au bon endroit : nous vous proposons -10% de réduction sur l'ensemble du site de la marque Kumiko Matcha, entreprise française offrant une large sélection de matchas biologiques en provenance directe du Japon ! 
            </p>
            <h2>Kumiko Matcha, le meilleur du thé matcha bio</h2>
            <p>
              Avec sa gamme de thé, Kumiko Matcha, ou "beauté éternelle" en japonais, entend proposer du matcha de qualité comme proposé au Japon, à la grande richesse des couleurs et des goûts, et respectueux de l'environnement. La marque propose une grande sélection de thés matcha, possédant chacun une histoire propre, résultat du savoir-faire des artisans-fermiers. 
            </p>
            <h2>-10% de réduction avec le code CHANOYU</h2>
            <p>
              Grâce à notre partenariat avec Kumiko Matcha, nous vous offrons -10% de réduction avec le code promo CHANOYU, pour tout achat sur le site officiel.
            </p>
            <h2>Notre recommandation : le thé matcha premium bio</h2>
            <p>
              Le thé matcha premium de la marque Kumiko Matcha est cultivé sur l'île de Kyushu. Il possède un goût doux et profond, parfaitement adapté à tout type de dégustation.
              A découvrir sur le site kumikomatcha.fr !
            </p>
          </div>
        </div>
      </div>
      <div>
        <Product id="code-promo-kumiko"/>
      </div>
      
      <Footer />
    </Layout>
  )
}

export default Privacy
